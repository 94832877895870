import BaseComponent from "@/components/base-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import { Contract, Recovery, PropertyVersion, PaymentGroup, TypeOfPayment } from "@/models";
import { ValidationError } from '@/models/interfaces/ValidationError';
import InputTextComponent from '@/components/common/input-text/input-text';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import PaymentFlowsComponent from '@/components/payment-flows/paymentflows';
import { ValidationRule } from "@/models/interfaces/ValidationRule";
import validationService from "@/services/validation-service";
import { TypeOfPaymentEnum } from "@/constants/fia-constants";

@Component({
    components: {
        YearMonthPickerComponent,
        PaymentFlowsComponent,
        InputTextComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        paymentGroupsList: "getPaymentGroupsNormal",
        typeOfPayments: "getTypeOfPayments"
    })
})
export default class ConctractRecoveriesEditComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    paymentGroupsList!: PaymentGroup[];
    typeOfPayments!: TypeOfPayment[];

    @Prop()
    contract!: Contract;
    @Prop()
    recovery!: Recovery;
    @Prop()
    complexErrors!: ValidationError[];
    @Prop()
    visible!: boolean;

    recoveryCloned: string = "";
    activeTypeOfPaymentsList: TypeOfPayment[] = [];
    paymentGroupId: number = -1;

    @Watch("typeOfPayments")
    typeOfPaymentsWatcher() {
        this.filterTypeList();
    }

    @Watch("contract", { deep: true })
    contractWatcher() {
        validationService.validateContract(this.contract, this.complexErrors);
    }

    @Watch("visible")
    visibleWatcher() {
        if (this.visible && this.recovery) {
            this.initialize();
        }
    }

    initialize() {
        this.recoveryCloned = JSON.stringify(this.recovery);
        let pt = this.typeOfPayments.find(x => x.id === this.recovery.costIncome.transaction.typeOfPaymentId);
        this.paymentGroupId = pt ? pt.paymentGroupId : this.paymentGroupsList[0].id;
        this.filterTypeList();
    }

    filterTypeList() {
        this.activeTypeOfPaymentsList = this.$store.getters.getActiveTypeOfPayments([this.recovery.costIncome.transaction.typeOfPaymentId], this.paymentGroupId);
    }

    isHGA(){
     
        return this.recovery?.costIncome?.transaction?.typeOfPaymentId == TypeOfPaymentEnum.TenantImprovement;
    }
       
    paymentGroupChanged() {
        this.filterTypeList();
        this.recovery.costIncome.transaction.typeOfPaymentId = this.activeTypeOfPaymentsList[0].id;
    }

    isNew(): boolean {
        return this.recovery!.id < 1;
    }

    async cancel() {
        if (!this.isNew() && this.recoveryDirty()) {
            try {
                await this.confirm('ConfirmDiscardChanges')
            } catch (error) {
                return;
            }
        }

        if (this.isNew()) {
            this.contract.recoveries.splice(this.contract.recoveries.indexOf(this.recovery), 1);
        }
        else {
            this.restoreRecovery();
        }

        this.close(true);
    }

    recoveryDirty(): boolean {
        return JSON.stringify(this.recovery) !== this.recoveryCloned;
    }

    restoreRecovery() {
        let ix = this.contract.recoveries.indexOf(<Recovery>this.recovery);
        let restored = JSON.parse(this.recoveryCloned);
        this.contract.recoveries.splice(ix, 1, restored);
    }

    close(canceled: boolean) {
        this.$emit("update:visible", false);
        this.$emit("closed", canceled);
    }

    async ok() {
        this.close(false);
    }

    recoveryValid(): boolean {
        return this.complexErrors.filter(x => x.rule !== ValidationRule.Warning).length === 0;
    }

    canSave(): boolean {
        return this.recoveryValid() && !this.versionLocked();
    }
}